import './App.css';
import img from './assets/bk.jpg'

function App() {
  return (
    <div className="App">
      <div className='tagline'>
        <h1>lowkey hospitality</h1>
        <a href="mailto:info@lowkeyhospitality.nyc">Contact Us</a>
      </div>
    </div>
  );
}

export default App;
